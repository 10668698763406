<template>
    <div>
        <v-container grid-list-md text-xs-center fluid>
            <h1>Relatório de pagamentos do dia</h1>
            <v-divider class="mb-4"></v-divider>

            <FilterDateClientStore hideDate hideClient @changeStore="changeStore" />

            <v-layout wrap class="my-12" v-if="items.length > 0">
                <v-flex xs12>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                R$ {{ total(items) }}
                            </div>
                            Total
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline">
                                <v-icon dark>attach_money</v-icon>
                                R$ {{ money(items) }}
                            </div>
                            Dinheiro
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline">
                                <v-icon dark>pix</v-icon>
                                R$ {{ pix(items) }}
                            </div>
                            PIX
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline">
                                <v-icon dark>credit_card</v-icon>
                                R$ {{ card(items) }}
                            </div>
                            Cartão débito e crédito
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                    <v-card color="error" v-if="canceled(items) > 0">
                        <v-card-text class="white--text">
                            <div class="headline">
                                <v-icon dark>functions</v-icon>
                                R$ {{ canceled(items) }}
                            </div>
                            Cancelados
                        </v-card-text>
                    </v-card>
                </v-flex>

                <v-flex xs12>
                    <v-card color="warning" dark>
                        <v-card-title class="text-h5"> R$ {{ expensesTotal }} </v-card-title>
                        <v-card-subtitle>
                            <div class="d-flex flex-no-wrap justify-space-between">
                                Despesas
                                <Pay v-if="selectedStore" :store="selectedStore.id" @update="updateExpenses()" />
                                <v-alert type="error" dense v-else> Seleciona uma loja para poder adicionar despesas </v-alert>
                            </div>
                        </v-card-subtitle>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-divider class="mb-4"></v-divider>
        </v-container>

        <SimpleTable v-if="items.length > 0" endpoint="payments/daily" :items="items" :aditionalColumns="headers" inlineToggle hideEdit hideDelete hideNew hideIdColumn>
            <template v-slot:extra-column="item">
                <v-chip class="ma-0" outlined color="success" v-if="item.createdAt"> {{ $moment(item.service.createdAt).format("DD/MM/YYYY") }} </v-chip>
            </template>
        </SimpleTable>
    </div>
</template>

<script>
import Pay from "./Pay";
import FilterDateClientStore from "@/components/shared/FilterDateClientStore";
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { FilterDateClientStore, SimpleTable, Pay },

    data() {
        return {
            items: [],
            dates: [],
            stores: [],
            selectedStore: null,
            expenses: [],
            headers: [
                { text: this.$t("Recebido por"), value: "audit.createdBy", align: "right", sortable: true },
                { text: this.$t("Valor"), value: "value", align: "right", sortable: true },
                { text: this.$t("Método"), value: "method", align: "right", sortable: true },
                { text: this.$t("Data do atendimento"), value: "extra-column", align: "right", sortable: true },
                { text: this.$t("Atendimento"), value: "service._id", align: "right", sortable: true },
            ],
        };
    },

    computed: {
        expensesTotal() {
            return Number.parseFloat(this.expenses.reduce((sum, i) => (i.enable ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
    },

    mounted() {
        // this.updateExpenses();
    },

    methods: {
        changeStore(event) {
            this.selectedStore = event;
            this.search();
        },
        search() {
            this.getPayments();
            this.updateExpenses();
        },
        getPayments() {
            let query = this.selectedStore ? `?store=${this.selectedStore.id}` : "";

            this.$http
                .get(`payments/daily${query}`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
        updateExpenses() {
            let query = this.selectedStore ? `?store=${this.selectedStore.id}` : "";

            this.$http
                .get(`expenses/daily${query}`)
                .then((result) => (this.expenses = result))
                .catch(() => (this.expenses = []));
        },
        total(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.enable ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        canceled(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.enable == false ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        pix(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.enable && i.method == "PIX" ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        money(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.enable && i.method == "Dinheiro" ? sum + parseFloat(i.value) : sum), 0)).toFixed(2);
        },
        card(items) {
            return Number.parseFloat(
                items.reduce((sum, i) => ((i.enable && i.method == "Cartão Crédito") || i.method == "Cartão Débito" ? sum + parseFloat(i.value) : sum), 0)
            ).toFixed(2);
        },
    },
};
</script>